.tablePlain {
  th {
    border: solid 1px #bbb;
    padding: 20px;
    text-align: left;
    font-family: 'themixarabicBold', sans-serif;
    font-weight: 400;
    cursor: pointer;

    > div {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: #000;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  td {
    border: solid 1px #bbb;
    padding: 20px;
  }

  @media (max-width: 767px) {
    border-top: solid 1px #bbb;
    tr,
    th,
    td {
      display: block;
      width: 100% !important;
    }
    th {
      border-top: 0;
    }
    td {
      border-top: 0;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  border: 1px solid #ddd;
  overflow: hidden;

  :global {
    li {
      padding: 0.25rem 0rem;
      &.disabled a {
        color: #ccc !important;
        cursor: not-allowed;
      }

      &.selected a {
        background-color: #2d6a80 !important;
        color: #fff !important;
      }
      a {
        color: #2d6a80;
        border: 1px solid #eee;
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          background-color: #eee;
          color: #23527c;
        }
      }
    }
  }
}

[dir='rtl'] .tablePlain {
  th {
    text-align: right;
  }
}
