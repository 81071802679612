.bgHeaderBackground {
  background-image: url('../../assets/img/regulations_header.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}

.pdfDownloadIcon {
  display: inline-block;

  svg {
    width: 24px;
    height: 24px;
  }
}
