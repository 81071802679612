.relatedWrap {
  background-color: #ffffff;
  padding-block: 10px;
  margin-block: 10px;
  box-shadow: 0 2px 10px rgba(0, 103, 130, 0.16),
    0 2px 5px rgba(0, 103, 130, 0.26);
}
.relatedImage {
  display: flex;
  height: 125px;
  padding-inline: 10px;
  @media (max-width: 1024px) {
    height: 100px;
  }
  @media (max-width: 568px) {
    height: 75px;
  }
}
.relatedTitle {
  font-size: 21px;
  padding-bottom: 5px;
  font-family: 'themixarabicLight', sans-serif;
}
.relatedLink {
  display: flex;
  justify-content: center;
}
.nextButton {
  position: absolute;
  top: 60px;
  right: 5px;
  cursor: pointer;
  background-color: #006782;
  color: #ffffff;
  padding: 5px;
}
.prevButton {
  position: absolute;
  top: 60px;
  left: 5px;
  cursor: pointer;
  background-color: #006782;
  color: #ffffff;
  padding: 5px;
}
