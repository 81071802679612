.bgHeaderBackground {
  background-image: url('../../../assets/img/eservice.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}
.remarksWrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 30px;

  h3 {
    color: #2d6a80;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'themixarabicExBold', sans-serif;
    font-size: 22px;
    margin: 0 0 20px 0;
  }
}

.remarksContain {
  h2 {
    color: #2d6a80;
    margin: 0 0 20px 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-family: 'themixarabicBold', sans-serif;

    &.remarksTopTitle {
      margin-top: 25px;
    }
  }
}

.remarksBox {
  border: solid 1px #2d6a80;
  padding: 30px 40px;

  p {
    color: #2d6a80;
    font-family: 'themixarabicBold', sans-serif;
    margin-bottom: 10px;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.remarksMap {
  padding: 55px 0;
}

.remarksForms {
  padding: 0 0 55px 0;
}
.remarksSocial {
  display: flex;
  margin-top: 40px;

  a {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 27px;

    img {
      transition: 0.2s;
    }

    &:hover img {
      filter: invert(55%) sepia(66%) saturate(2829%) hue-rotate(0deg)
        brightness(102%) contrast(106%);
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
.homeButtonWrapper {
  display: flex;
  justify-content: left;
}
.homeButton {
  text-transform: uppercase;
  font-family: 'themixarabicBlack', sans-serif;
  color: #00b2e3;
  text-decoration: underline;
  text-align: left;
  display: block;
  margin-bottom: 20px;
  // width: 150px;
}
.arrow {
  margin-inline: 5px;
}
body[dir='rtl'] {
  .homeButtonWrapper {
    display: flex;
    justify-content: right;
  }
}

.hTwoTitlePage {
  color: rgba(0, 0, 0, 0.8);
  margin: 0 0 20px 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  font-family: 'themixarabicBold', sans-serif;
}
