.navbar {
  background-color: #006782;
  padding: 23px 35px;
  z-index: 2;
  position: sticky;
  top: 0;

  @media (max-width: 1025px) {
    padding: 8px 15px;
  }

  @media (min-width: 1025px) and (max-width: 1065px) {
    padding: 23px 16px;
  }

  .navbarContainer {
    max-width: 100%;
    padding: 0;
    gap: 2rem;

    @media (max-width: 1390px) {
      gap: 1rem;
    }

    @media (max-width: 1124px) {
      gap: 0.5rem;
    }
  }

  .logoWrapper {
    i {
      color: white;
      margin-right: 37px;
    }

    display: flex;
    align-items: center;

    button {
      span {
        height: 3px;
        width: 28px;
        margin: 6px 0;
      }
    }
  }

  .navlink {
    a {
      font-size: 16px;
      padding: 0px;
      font-family: 'themixarabicBold', sans-serif;
      font-weight: 400;
      border-radius: 0;
      color: #fff;

      @media (max-width: 1023px) {
        padding: 14px 15px;
        margin: 0 -15px;
        border-bottom: solid 1px darken(#006782, 5%);
      }

      @media (max-width: 1240px) {
        font-size: 14px;
      }
      @media (max-width: 1110px) {
        font-size: 12px;
      }
    }
  }

  .navDrop {
    gap: 2rem;
    @media (max-width: 1530px) {
      gap: 1rem;
    }
    @media (max-width: 1390px) {
      gap: 0.75;
    }
    @media (max-width: 1300px) {
      gap: 0.5rem;
    }
    @media (max-width: 1110px) {
      gap: 0.5rem;
    }
  }

  .profile {
    svg {
      color: white;
    }
    margin-left: 15px;
  }
}
.rightSide {
  display: flex;
  gap: 2rem;
  a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
    // text-align: left;
    font-family: 'themixarabicLight', sans-serif;
    border: 2px solid #3da3bd;
    border-radius: 8px;
    padding: 10px;
    text-transform: uppercase;
    @media (max-width: 1530px) {
      font-size: 14px;
    }
  }
  @media (max-width: 1390px) {
    gap: 0.2rem;
  }
}
.langSwitchButton {
  display: flex;
  align-self: center !important;
}
.langSwitch {
  color: #fff;
  display: flex;
  align-items: center;
  direction: ltr;
  gap: 0.25rem;
  font-size: 16px;

  img {
    border: solid 1px rgba(255, 255, 255, 0.6);
    border-radius: 3px;
    width: 25px;
  }

  @media (max-width: 1240px) {
    font-size: 14px;
    img {
      width: 22px;
    }
  }
}

.navWrapper {
  margin-left: 0;
  width: 100%;
}

@media (max-width: 1000px) {
  .navWrapper {
    padding-bottom: 20px;
    max-height: 90vh;
    overflow: scroll;
  }
}

.subItemsContainer {
  @media (min-width: 1131px) {
    top: 102%;
  }
}

.hamburgerMenuLangSwitcherContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
