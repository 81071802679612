.welcome {
  color: #006782;
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: 53px;
  line-height: 63px;
  text-transform: uppercase;
}
.bannerTitle {
  font-size: 63px;
  line-height: 68px;
  strong {
    font-family: 'themixarabicBlack', sans-serif;
  }
}
.mainBannerWrap {
  max-width: 760px;
}
.searchWrapper {
  border: 3px solid white;
  color: white;
  text-align: center;

  padding: 5px 30px 50px 30px;
  border-radius: 115px;

  .title {
    padding: 0 20px;

    @media (max-width: 639px) {
      padding: 10px 30px;
    }
  }

  .selectItem {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  .mainFormField {
    border-bottom: solid 1px #fff;
    select {
      background: none;
      color: #fff;
      font-size: 15px;
      font-family: 'themixarabicPlain', sans-serif;

      option {
        color: #000;
      }

      option:first-child {
        display: none;
      }

      &:focus,
      &:focus-visible {
        border: 0;
        outline: none;
      }
    }
  }

  .formFieldWrap {
    display: flex;
    width: 100%;
  }

  .mainFieldWrap {
    height: 55px;
    justify-content: end;
    margin-right: 30px;
  }

  .form {
    display: flex;
    label {
      color: white;
      font-family: 'themixarabicPlain', sans-serif;
      font-size: 15px;
      margin-top: 8px;
      height: 21px;
    }
    .select {
      border-bottom: 1px solid white;
      font-size: 15px;
      margin-top: 23px;
      height: 55px;
      font-family: 'themixarabicPlain', sans-serif;

      svg {
        color: white;
      }

      option {
        color: white;
        font-size: 18px;
      }

      div {
        display: flex;
        color: white;
        padding: 0;
      }
    }
  }
}

body[dir='rtl'] {
  .searchWrapper .form {
    label {
      left: auto;
      right: 0;
    }
    svg {
      right: auto;
      left: 0;
    }
  }
}

.selectItem {
  font-family: 'themixarabicPlain', sans-serif !important;
  font-size: 18px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;

  &:last-child {
    border: 0 !important;
  }
}

.videoContain {
  margin-top: -95px;
}

.videoWrapper {
  background-color: black;

  .video {
    opacity: 0.6;
    width: 100%;
    height: 100vh;
    max-height: 1100px;
    min-height: 720px;
    object-fit: cover;
  }

  .videoIMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.videoContent {
  padding-top: 95px;
  height: 100vh;
  max-height: 1000px;
}

.mainBannerSearch {
  width: 27px;
  height: 27px;
}

@media (max-width: 1023px) {
  .videoContain {
    margin-top: -77px;
  }
  .videoContent {
    padding-top: 77px;
  }
  .bannerTitle {
    font-size: 45px;
    line-height: 55px;
  }
}

@media (max-width: 639px) {
  .form .formFieldWrap {
    display: block;
    padding-top: 15px;
  }
  .formFieldWrap .mainFieldWrap {
    margin-bottom: 10px;
  }
  .bannerTitle {
    font-size: 31px;
    line-height: 40px;
  }
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
