.input {
  display: flex;
  background: #f2f2f2;
  gap: 0.25rem;

  input {
    height: 100%;
    margin: 0px;
    border-radius: 0px;
  }

  button {
    padding: 10px;
  }
}

.datePicker {
  width: 100%;
  height: 100%;
}
