.bgHeaderBackground {
    background-image: url('../../assets/img/jobs.png');
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media(max-width:767px) {
        height: 200px;
      }
  }

  .jobsWrap {
    h2 {
        font-weight: 400;
    }
  }
