.homeServiceTitle {
  color: #006782;
  text-align: start;
  font-style: normal;
  max-width: 620px;
  font-size: 53px;
  line-height: 63px;
  margin: 0 0 45px 0;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'themixarabicPlain', sans-serif;

  strong {
    font-family: 'themixarabicExBold', sans-serif;
  }
}

.responsiveIframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.responsiveIframe iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.imageCarouselWrap {
  padding-top: 55px;
  padding-bottom: 55px;
  background: #f3f3f3;
}

.homeHistoryTitle,
.homeCenterTitle {
  width: 100%;

  color: #006782;
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: 53px;
  margin: 0 0 30px 0;
  line-height: 63px;
  text-transform: uppercase;
  font-family: 'themixarabicPlain', sans-serif;

  strong {
    font-family: 'themixarabicExBold', sans-serif;
  }
}

.homeCenterTitleWrap {
  text-align: center;
}

.homeCenterTitle {
  text-align: center;
  padding-bottom: 55px;
  margin: 55px 0 0;
  max-width: 70%;
  display: inline-block;

  @media (max-width: 639px) {
    max-width: 100%;

    padding-bottom: 0;
  }
}

.homeImageTitle {
  width: 100%;
  bottom: 90px;
  color: #006782;
  text-align: start;
  font-style: normal;
  margin-bottom: 40px;
  margin-top: 0;
  font-weight: 540;
  font-size: 40px;
  line-height: 52px;
  text-transform: uppercase;
  font-family: 'themixarabicExBold', sans-serif;
}
.homeHistoryWrap {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  @media (max-width: 1023px) {
    display: block;
  }
}
.historyCarouselWrap {
  padding-top: 90px;
  padding-bottom: 90px;
}
.homeHistoryDetail {
  max-width: 490px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:10px;

  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 0;
    margin-bottom: 40px;
  }
}
.round {
  border: 0px solid #d9d8d6;
  padding: 34px 34px;
  border-radius: 100%;
  background-color: #ecedf0;
}
.mediaSectionWrap {
  padding-top: 110px;
  padding-bottom: 90px;
}
.homeMediaButtons {
}

.mediaSectionButton {
  margin-right: 0.5rem;
  padding: 9px 20px;
  border-radius: 45px;
  font-size: 18px;
  border: 1px solid #00b2e3;
  font-family: 'themixarabicPlain', sans-serif;
}
.mediaBreakout {
  margin: 0 -100% 0 0; /* old browsers fallback */
  margin: 0 calc(50% - 49vw) 0 0;
}
.mediaSectionButton:focus {
  outline: none;
}

.mediaTiles {
  display: flex;
  min-width: 340px;
  margin-left: -40px;
  margin-right: -40px;
}
.mediaTileMain {
  width: 580px;
  padding-right: 40px;
}

.mediaTileSingle {
  max-width: 26%;
  padding: 0 40px;

  border-left: solid 1px #e1e1e1;

  &:last-child {
    border-right: 0;
  }

  img {
    margin-bottom: 30px;
  }
}

.mediaTileReadMore {
  text-transform: uppercase;
  font-family: 'themixarabicBlack', sans-serif;
  text-decoration: underline;
  color: #ff8300;
  display: block;
  margin: 30px 0 0;
}

.mediaTileMainTitle {
  font-size: 37px;
  margin: 0 0 20px 0;
  line-height: 44px;
  font-family: 'themixarabicBold', sans-serif;
  font-weight: 400;
}

.mediaTileText {
  font-size: 16px;
  line-height: 26px;
  opacity: 0.8;
  max-width: 460px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mediaTileTitle {
  font-size: 30px;
  margin: 0 0 20px 0;
  line-height: 38px;
  font-family: 'themixarabicBold', sans-serif;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mediaSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
}

.mediaSectionNews {
  font-style: normal;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #54565a;
  font-family: 'themixarabicBold', sans-serif;
}

.alice-carousel {
  position: relative;
  width: 41%;
  margin: auto;
  direction: ltr;
}

.mediaSectionUpToDate {
  font-style: normal;

  font-size: 35px;
  line-height: 44px;
  /* or 126% */
  color: #000000;
  width: 100%;
  padding-bottom: 1rem;
  font-family: 'themixarabicBold', sans-serif;
}

.mediaSectionFollow {
  font-style: normal;

  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;

  color: #2c3038;

  mix-blend-mode: normal;
  opacity: 0.8;

  font-family: 'themixarabicLight', sans-serif;
}

.textbox {
  height: 150px;
  width: 309px;
  left: 20px;
  top: 45px;
  border-radius: nullpx;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin: auto;
  padding-top: 40px;
  font-family: 'themixarabicLight', sans-serif;
}

.textfullbox {
  height: 293px;
  width: 355px;
  left: 200px;
  top: -0.3330078125px;
  border-radius: 0px;

  background-color: white;
}

.readMore {
  height: 29px;
  width: 155px;
  left: 23px;
  top: 198px;
  border-radius: nullpx;
  position: absolute;

  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 30px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #ff8300;
  font-family: 'themixarabicBold', sans-serif;

  cursor: pointer;
}

.sliderInfo {
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 365px;
  font-size: 19px;
  line-height: 26px;
  padding: 45px 24px;
  color: #23282d;
  text-align: start;
  * {
    text-align: start;
  }

  a {
    text-decoration: underline;
    text-transform: uppercase;
    color: #ff8300;
    font-size: 17px;
    display: block;
    margin-top: 25px;
    font-family: 'themixarabicBlack', sans-serif;
  }
  p{
    text-align: justify;
  }
}

.h_textbox {
  font-size: 18px;

  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'themixarabicLight', sans-serif;
}
.h_readMore {
  width: 445px;
  height: 30px;
  font-family: 'themixarabicBlack', sans-serif;
  font-style: normal;

  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #ff8300;
  margin-top: 20px;
  display: block;
}
.imgStyle {
  max-width: 200px;
  margin: auto;
}
.serviceSectionWrap {
  padding-top: 90px;
  padding-bottom: 90px;
}
.serviceStateWrap {
  display: flex;
  justify-content: space-evenly;
}

.serviceState {
  text-align: center;
  //  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-size: 21px;
    line-height: 29px;
    max-width: 175px;
    transition: 0.2s;

    font-family: 'themixarabicPlain', sans-serif;
  }

  .round {
    background: #f4f4f4;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 160px;
    height: 160px;
    border-radius: 100%;
    margin-bottom: 27px;
    transition: 0.2s;
    align-items: center;
  }
  .roundImage {
    width: 60px;
    max-height: 60px;
  }

  &:hover {
    .round {
      background: #ff8300;
    }

    strong {
      font-family: 'themixarabicBold', sans-serif;
    }

    .roundImage {
      filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
    }
  }
}

@media (max-width: 1400px) {
  .mediaTileSingle {
    max-width: 30%;
  }
}

@media (max-width: 1023px) {
  .homeServiceTitle {
    max-width: 100%;
  }
  .serviceStateWrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .serviceState {
    margin-bottom: 20px;
    .round {
      width: 120px;
      height: 120px;
    }
  }
  .homeMediaButtons {
    margin-left: 0;
    margin-top: 30px;
  }
  .mediaSectionHeader {
    display: block;
  }
}

@media (max-width: 767px) {
  .mediaTileMain {
    width: 300px;
  }

  .mediaTiles {
    margin-left: -20px;
    margin-right: -20px;
  }

  .mediaTileSingle {
    padding: 0 20px;
  }
  .mediaSectionButton {
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;
  }
}

@media (max-width: 920px) {
  .sliderInfo {
    max-width: 90%;
    padding: .5rem .7rem;

    a {
      margin-top: 4px;
    }
  }
}

@media (max-width: 420px) {
  .sliderInfo {
    position: initial;
    max-width: 100%;
    padding: 2rem .7rem;
  }
}

@media (max-width: 639px) {
  .historyCarouselWrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .sliderInfo {
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .homeImageTitle {
    font-size: 30px;
    line-height: 40px;
    br {
      display: none;
    }
  }

  .homeHistoryTitle,
  .homeServiceTitle,
  .homeCenterTitle {
    font-size: 38px;
    line-height: 48px;
  }
  .h_textbox {
    font-size: 16px;
    line-height: 26px;
  }

  .imageCarouselWrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mediaTileSingle {
    max-width: 50%;
  }
  .serviceSectionWrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mediaSectionWrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .mediaTileMainTitle {
    font-size: 30px;
    line-height: 37px;
  }
  .mediaTileTitle {
    font-size: 25px;
    line-height: 35px;
  }
}

.viewAllContainer{
  display: flex;
  justify-content: flex-end;
}

.sliderInfoNews {
  background: #fff;
  width: 30%;
  font-size: 19px;
  line-height: 26px;
  padding: 45px 24px;
  color: #23282d;
  text-align: start;
  * {
    text-align: start;
  }

  a {
    text-decoration: underline;
    text-transform: uppercase;
    color: #ff8300;
    font-size: 17px;
    display: block;
    margin-top: 25px;
    font-family: 'themixarabicBlack', sans-serif;
  }
  
}

.customFlex{
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .sliderInfoNews {
    width: 100%;
    min-height:200px;
  }
}