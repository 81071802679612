.bgHeaderBackground {
  background-image: url('../../assets/img/eservice.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}
.serviceContainer {
  display: block;
}
.eServiceWrapper > div {
  .tabs {
    button {
      border-bottom: 1px solid;
      display: flex;
      align-items: flex-start;
      &.selected {
        color: orange !important;
      }
    }
  }
}

.submitBtn {
  display: flex;
  flex-direction: row-reverse;
  align-self: center;
}

.feeTableHeader {
  background-color: #48a2b8;
  vertical-align: middle;
  color: white;
  text-align: center;
  border: 1px solid white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'themixarabicBold';
}
.feeTableHeaderSmall {
  background-color: #48a2b8;
  vertical-align: middle;
  color: white;
  text-align: center;
  border: 1px solid white;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 10%;
  font-family: 'themixarabicBold';
}
.feeTableHeaderLeft {
  background-color: #48a2b8;
  vertical-align: middle;
  color: white;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border: 1px solid white;
  width: 35%;
  font-family: 'themixarabicBold';
}
body[dir='rtl'] {
  .feeTableHeaderLeft {
    text-align: right;
    border-top-left-radius: 0;
  }
  .feeTableHeader {
    border-top-left-radius: 0;
  }

  .feeTableLeftRadius {
    border-top-right-radius: 0;
    border-top-left-radius: 0.5rem;
  }
}
.table {
  width: 100%;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.feeTableCellAuto {
  border: 1px solid white;
  background-color: #f1f5f9;
  margin: auto;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.feeTableCellLeft {
  border: 1px solid white;
  background-color: #f1f5f9;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px4;
}
.buttonRequest {
  background-color: #2d6a80;

  color: white;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 5px;
}
.tabButton {
  min-width: 31%;
  width: 31%;
  margin: 0.5%;
}
.contentContainer {
  margin-top: 20px;
}

.buttonContainer {
  margin: 0 -7px;
}
.serviceContainer {
  .service {
    // max-width:31%;
    width: 100%;
    min-height: 14em;
    display: inline-block;
    padding: 10px;
    flex: 1 1 31%;
    margin: 1%;
    border: 1px solid #efefef;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    a {
      margin: 2px;
      border: 1px solid #efefef;
      padding: 10px;
      border-radius: 22px;
      display: block;
      margin-bottom: 4px;
      text-align: center;

      &:hover {
        margin-top: 2px;
        background-color: #fff;
        color: #2d6a80;
      }
    }

    .serviceFront {
      height: 100%;
      top: 50%;
      position: relative;
      transform: translate(0%, 50%);
    }
    .serviceContent {
      z-index: 1;
      position: absolute;
      top: -100%;
      transition: 0.2s;
      color: #fff;
      background-color: #2d6a80;
      background: rgb(0, 178, 227);
      background: linear-gradient(
        177deg,
        rgba(0, 178, 227, 1) 35%,
        rgba(45, 106, 128, 1) 100%
      );
      width: 100%;
      align-self: stretch;
      height: 100%;
      padding: 10px;
      left: 0;

      .serviceDescription {
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .serviceTitle {
      font-size: 21px;
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }
    &:hover {
      .serviceContent {
        transition: 0.2s;

        top: 0;
      }
    }
  }
}
.eServiceContent {
  .serviceIntro {
    .serviceImg {
      display: inline-block;
      margin-top: 40px;
    }

    h3 {
      color: #2d6a80;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
    }

    ol {
      list-style: decimal;
      padding-left: 20px;
    }
  }
}

body[dir='rtl'] {
  .eServiceContent {
    .serviceIntro {
      ul {
        padding-right: 20px;
        padding-left: 0;
      }

      ol {
        padding-right: 20px;
        padding-left: 0;
      }
    }
  }
}

.serviceIcon {
  height: 45px;
}

.tabTitle {
  display: block;
  font-weight: 400;
  font-family: 'themixarabicPlain';
  margin-top: 10px;
}

.eServiceContent {
  h2 {
    font-weight: 400;
    font-size: 30px;
    font-family: 'themixarabicBold';
    margin: 0 0 10px 0;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.8);
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    margin: 0 0 10px 0;
    font-family: 'themixarabicBold';
    color: rgba(0, 0, 0, 0.8);
  }

  p {
    margin-bottom: 25px;
  }
}

.radioGroup {
  font-family: 'themixarabicLight';
  display: flex;
  margin-bottom: 30px;

  .radioItem {
    margin-right: 60px;
    cursor: pointer;

    input {
      margin-right: 5px;
    }

    @media (max-width: 639px) {
      margin-right: 20px;
    }
  }
}
body[dir='rtl'] {
  .radioGroup {
    .radioItem {
      margin-right: 0;
      margin-left: 60px;

      input {
        margin-right: 0;
        margin-left: 5px;
      }

      @media (max-width: 639px) {
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }
}
.splitForm {
  display: grid;

  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: 589px) {
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.splitFormCol {
  display: block;
  max-width: 50%;
}

.splitSearch {
  display: flex;

  div {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 890px) {
    display: block;

    div {
      margin: 0 0 30px 0;
    }
  }
}

body[dir='rtl'] .splitSearch {
  div {
    margin-right: 0;
    margin-left: 30px;

    &:last-child {
      margin-left: 0;
    }
  }
}

.serviceSteps,
.serviceStepsFive,
.serviceStepsFour {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 639px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .step {
    padding: 15px 15px;
    border: 1px solid #e3e3e3;
    background: #f9f9f9;
  }

  .number {
    font-family: 'themixarabicBold';
    font-size: 28px;
    color: #fcb732;
    text-align: center;
    border-radius: 50%;
    border: solid 2px #48a2b8;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: #fff;
  }

  .title {
    font-size: 14px;
    font-family: 'themixarabicPlain';
  }
}

// body[dir="rtl"]  {
//   .serviceSteps, .serviceStepsFive, .serviceStepsFour {
//     .number {

//       font-size: 16px;

//     }
//   }
// }

.serviceStepsFive {
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 639px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.serviceStepsFour {
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 639px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pdfSectionContainer {
  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

.square {
  border: 1px solid #e3e3e3;
  padding: 20px 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 190px;
  align-items: center;
  width: 100%;
}

.squareWrap {
  margin: 0 30px 30px 0;
  display: flex;
  max-width: 213px;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  align-items: center;

  @media (max-width: 550px) {
    margin: 0 20px 20px 0;
    width: calc(50% - 20px);
  }
}

body[dir='rtl'] {
  .squareWrap {
    margin: 0 0 30px 30px;

    @media (max-width: 550px) {
      margin: 0 0 20px 20px;
    }
  }
}

.pdfFileNames {
  font-family: 'themixarabicPlain', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 0 20px;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 0 !important;

  color: rgba(0, 0, 0, 0.8);
}

.zipIcon {
  width: 105px;
}
.mediaContain {
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}
.tabContent {
  padding-left: 40px;
  width: 100%;

  @media (max-width: 767px) {
    padding-left: 0;
  }
}

body[dir='rtl'] .tabContent {
  padding-left: 0;
  padding-right: 40px;
  @media (max-width: 767px) {
    padding-right: 0;
  }
}

.sideBar {
  max-width: 200px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.tablePlain {
  margin-bottom: 20px;
  th {
    border: solid 1px #bbb;
    padding: 20px;
    text-align: left;
    font-family: 'themixarabicBold', sans-serif;
    font-weight: 400;
  }
  td {
    border: solid 1px #bbb;
    padding: 20px;
  }

  @media (max-width: 767px) {
    border-top: solid 1px #bbb;
    tr,
    th,
    td {
      display: block;
      width: 100% !important;
    }
    th {
      border-top: 0;
    }
    td {
      border-top: 0;
    }
  }
}
.contactContain {
  h2 {
    color: #2d6a80;
    margin: 0 0 20px 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-family: 'themixarabicBold', sans-serif;
  }
}

.contactForm {
  padding: 0 0 55px 0;
}

.quantityInput {
  background-color: ghostwhite !important;
  border: 1px solid #f2f2f2;
  height: 50px;
  width: 100%;
  padding: 10px;
  opacity: 0.5 !important;
  outline: none !important;
}

.announcement {
  padding: 10px;
  border: 1px solid #b64956;
  background: #f2dede;
  color: #b64956;
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 10px;

  & .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    font-family: 'themixarabicBold', sans-serif;
    text-align: center;
  }
  & .description {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    text-align: justify;
    font-family: 'themixarabicPlain', sans-serif;
    text-align: center;
  }
}
.biddingTable {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !important;
  font-weight: normal !important;
}

.tableColored {
  margin-bottom: 20px;
  th {
    border: solid 1px #bbb;
    padding: 20px;
    background-color: #2d6a80;
    text-align: center;
    font-family: 'themixarabicBold', sans-serif;
    font-weight: 400;
    color: #fff;
  }
  td {
    border: solid 1px #bbb;
    padding: 20px;
    text-align: center;
  }
}

.homeButtonWrapper {
  display: flex;
  justify-content: left;
}
.arrow {
  margin-inline: 5px;
}
.homeButton {
  text-transform: uppercase;
  font-family: 'themixarabicBlack', sans-serif;
  color: #00b2e3;
  text-decoration: underline;
  text-align: left;
  display: block;
  margin-bottom: 20px;
  // width: 150px;
}

body[dir='rtl'] {
  .homeButtonWrapper {
    display: flex;
    justify-content: right;
  }
}

.hTwoTitlePage {
  color: rgba(0, 0, 0, 0.8);
  margin: 0 0 20px 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  font-family: 'themixarabicBold', sans-serif;
}
