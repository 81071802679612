.wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("/assets/img/landingImg.png");
}

.videoWrapper {
  background-color: black;
 

  .video {
    opacity: 1;
    width: 100%;
    height: 100vh;
    
    object-fit: cover;
  }

  .videoIMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.overlay {
  position:absolute;
  top:0;
  bottom:0;
  display:block;
  left:0;
  right:0;
  width:100%;
  background-color:rgba(0, 0, 0, 0.35)
}
.logo{
  position:fixed;
  top:20px;
  right:20px;
  padding-bottom: 0px;
}
.landingTitle {
  font-size: 80px;
line-height: 86px;
  strong {
    font-weight: 400;
    font-family: "themixarabicBlack", sans-serif;
  }
}
.landingSubTitle {
  font-family: "themixarabicBlack", sans-serif;
  font-size: 40px;
line-height: 65px;
}
hr {
  min-width:92px;
}
.smallHeadings {
  letter-spacing: 3px;
}
.portName {
padding-top:2px;}

@media(max-width:1023px) {
  
  
  .landingTitle {
    font-size: 65px;
    line-height: 75px;
    
  }
  
}

@media(max-width:767px) {
  
  
  .landingTitle {
    font-size: 45px;
    line-height: 55px;
    
  }
  
}