.bgHeaderBackground {
  background-image: url('../../assets/img/AboutUs.JPEG');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 25%;

  @media (max-width: 767px) {
    height: 200px;
  }
}
.title {
  text-align: center;
  padding: 10px;
  font-family: 'themixarabicBold', sans-serif;
}

.subTitle {
  text-align: justify;
  text-decoration: underline;
  font-family: 'themixarabicLight', sans-serif;
  font-weight: bold;
  padding-top: 10px;
}
.announcement {
  text-align: justify;
  padding-top: 15px;
  font-family: 'themixarabicLight', sans-serif;
  // text-decoration: underline;
}
.afterAnnouncement {
  text-align: center;
  padding-top: 15px;
  padding-right: 5px;
  font-family: 'themixarabicLight', sans-serif;
}
.table {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  direction: ltr;
  text-align: center;
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th {
    background-color: #96d4d4;
  }
  td {
    padding-inline: 10px;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    font-family: 'themixarabicBold', sans-serif;
  }
  div {
    padding-top: 30px;
    padding-bottom: 30px;
    table {
      padding-bottom: 20px;
      th {
        text-align: justify;
      }
    }
  }
}
.bullet {
  font-family: 'themixarabicBold', sans-serif;
  padding-top: 10px;
  padding-bottom: 30px;
}
.arabicHeader {
  text-align: right !important;
}
