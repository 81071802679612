.bgHeaderBackground {
  background-image: url('../../assets/img/mediacenter.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}

.col2Wrapper > div {
  margin: 0 auto;
  margin-top: -60px;
  margin-top: -60px;
  border-radius: 20px;
  width: 80%;
  background-color: #fff;
  padding: 10px 20px;

  .tabs {
    button {
      border-bottom: 1px solid;
      display: flex;
      align-items: flex-start;
      &.selected {
        color: orange !important;
      }
    }
  }
}

.submitBtn {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 30px;

  button {
    border-radius: 30px;
  }
}

.datePicker {
  height: 43px;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: solid 1px #e1e1e1;
  .meta {
    margin: 0 0 20px 0;
    .date {
      margin-bottom: 5px;
      font-size: 14px;
      color: #54565a;
      font-family: 'themixarabicBold', sans-serif;
    }
    h2 {
      font-size: 30px;
      line-height: 36px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'themixarabicBold', sans-serif;
    }
  }
  p {
    color: rgba(44, 48, 56, 0.8);
    margin-bottom: 35px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
  }
  .readLink {
    text-transform: uppercase;
    font-family: 'themixarabicBlack', sans-serif;
    color: #ff8300;
    text-decoration: underline;
    margin-bottom: 30px;
    display: block;
  }
  img {
    margin-top: auto;
    height: 205px;
    object-fit: contain;
    overflow: hidden;
    @media (max-width: 640px) {
      height: 250px;
    }
  }
}

.videoCard {
  .caption {
  }
}
.mediaCenterContent {
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 400;
    font-size: 30px;
    font-family: 'themixarabicBold';
    margin: 0 0 10px 0;
    color: rgba(0, 0, 0, 0.8);
  }
}
.mediaContain {
  display: flex;
  @media (max-width: 767px) {
    display: block;
    max-width: 100%;
  }
}
.tabContent {
  padding-left: 40px;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
  }
}

body[dir='rtl'] {
  .tabContent {
    padding-left: 0;
    padding-right: 40px;
    @media (max-width: 767px) {
      padding-right: 0;
    }
  }
}

.sideBar {
  max-width: 200px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.sideBarSub {
  margin-top: 60px;
}

.mediaCenterGrid {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;

  grid-template-columns: repeat(3, minmax(30%, 1fr));

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }

  @media (max-width: 639px) {
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
}

.libraryModal {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 100px;
}

.libraryDescription {
  text-align: justify;
}

.newsletterCardImage {
  aspect-ratio: 1/1.2;
  height: auto !important;
}

.newsletterCardImageContainer {
  overflow: hidden;
  img {
    transition: scale 300ms ease-in-out;
    transition: scale 500ms ease-out;
  }
  &:hover {
    // box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 55px inset;
    opacity: 0.95;
    img {
      transition: scale 300ms ease-in-out;
      scale: 1.02;
    }
  }
}
