.bgHeaderBackground {
  background-image: url('../../assets/img/eservice.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.eServiceWrapper > div {
  margin: 0 auto;
  margin-top: -60px;
  margin-top: -60px;
  border-radius: 20px;
  width: 80%;
  background-color: #fff;
  padding: 10px 20px;
}

.accordianWrapper > div {
  background: none !important;
  box-shadow: none;
  position: static;
  > div {
    padding: 0 !important;
  }

  .bgImage1 {
    background-image: url('../../assets/img/accordian1.png');
    height: 150px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .accordianHeader {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      background: rgba(45, 106, 128, 0.7);
      height: 100%;
      border-radius: 15px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 30px;
      padding: 0px 40px;
      font-family: "themixarabicBold", sans-serif;
    }
  }
}
