.bgHeaderBackground {
  background-image: url('../../assets/img/mediacenter.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}
.errorDescription {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.linkStyle {
  color: #48a2b8;
  text-decoration: underline;
  display: block;
}
