.container {
  padding-bottom: 20px;
}
.title {
  font-weight: bold;
}

.description {
  font-family: 'themixarabicLight', sans-serif;
  color: 'rgba(44, 48, 56, 0.8)';
  & span {
    font-family: 'themixarabicLight', sans-serif !important;
    color: rgba(44, 48, 56, 0.8) !important;
  }
}
.buletPoints {
  list-style-type: disc;
  margin-left: 20px;
  padding-block: 10px;
  margin-right: unset;
}

body[dir='rtl'] {
  .buletPoints {
    margin-right: 20px;
    margin-left: unset;
  }
}
