
.bgHeaderBackground {
    background-image: url('../../assets/img/news/1.jpg');
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .col2Wrapper > div {
    margin: 0 auto;
    margin-top: -60px;
    margin-top: -60px;
    border-radius: 20px;
    width: 80%;
    background-color: #fff;
    padding: 25px 30px 30px 30px;
  
   
  }
  
  .submitBtn {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 30px;
  
    button {
      border-radius: 30px;
    }
  }
  


  .articleContent {
        p {
            font-size: 21px;
            line-height: 30px;
        }
  }
  

 .readLink {
    text-transform: uppercase;
    font-family: "themixarabicBlack", sans-serif;
    color: #FF8300;
    text-decoration: underline;
  
    display: block;
    margin-top: 40px;
  }