.chatbot {
  position: fixed;
  bottom: 30px;
  right: 50px;
  left: unset;
}
body[dir='rtl'] {
  .chatbot {
    position: fixed;
    bottom: 30px;
    left: 50px;
    right: unset;
  }
}

.firstButton {
  width: 70px;
  border-radius: 70px;
}
.secondButton {
  width: 50px;
  background-color: #00afaa;
  border-radius: 10px;
}

.chat {
  width: 50px;
}
.callBack {
  width: 50px;
}
.callBackList {
  width: 300px;
  background-color: #f6f6f6;
  // background-color: black;
}

.callBackQOne input,
.callBackQOne textarea {
  width: 80% !important;
  display: flex;
  justify-content: center;
  background: #eaeaea;
  border: solid 1px #eaeaea;
  margin-block-end: 10px;
  margin-inline-start: 30px;
}

.callBackQTwo input,
.callBackQTwo textarea {
  width: 80% !important;
  display: flex;
  justify-content: center;
  background: #eaeaea;
  border: solid 1px #eaeaea;
  margin-block-end: 10px;
  margin-inline-start: 30px;
}

.callBackQThree input,
.callBackQThree textarea {
  width: 80% !important;
  display: flex;
  justify-content: center;
  background: #eaeaea;
  border: solid 1px #eaeaea;
  margin-block-end: 10px;
  margin-inline-start: 30px;
}

.callBackFormTitle {
  background-color: #00afaa;
  color: white;
  text-align: center;
}
.callBackSendButton {
  background: #00afaa;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  font-family: 'themixarabicLight', sans-serif;
  outline: 0;
  box-shadow: none;
  transition: 0.2s;
  color: #fff;
  padding: 9px 45px;
  margin-inline-start: 85px;
  margin-block-end: 10px;
}
.closeIcon {
  position: absolute;
  top: 26px;
  left: 13px;
}
.minIcon {
  position: absolute;
  top: 10px;
  left: 33px;
  font-size: 24px;
}
