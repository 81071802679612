
.stateContainer {
    padding-top: 90px;
   

    h2 {
        color: #006782;
        text-align: start;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        font-size: 53px;
        margin: 0 0 50px 0;
        line-height: 63px;
        text-transform: uppercase;
        font-family: "themixarabicPlain", sans-serif;

        @media(max-width:639px) {
            font-size: 38px;
            line-height: 48px;
        }
    }
}

.state {
    text-align: center;
  //  min-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong, span {
        display: block;
        color: #54565B;
        line-height: 33px;
    }

    strong {
        font-size: 33px;
    }

    span {
        font-size: 25px;
        font-family: "themixarabicLight", sans-serif;
    }
}

.round {
    border: 1px solid #D9D8D6;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 160px;
    height: 160px;
    border-radius: 100%;
    margin-bottom: 27px;
    align-items: center;
}
.roundImage {
    width: 60px;
    max-height: 60px;
}

.stateWrap {
    padding-bottom: 90px;
}

  
@media (max-width: 1023px) {
    .round {
        width: 120px;
        height: 120px;
        margin-bottom: 15px;
        
    }
    .roundImage {
        width: 40px;
        max-height: 40px;
    }

    .state {
     
        strong, span {
            line-height: 25px;
        }
    
        strong {
            font-size: 25px;
        }
    
        span {
            font-size: 18px;
        }
    }
}

@media (max-width: 767px) {
   
    .stateWrap {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 40px;
    }

    .state {
        margin: 0 20px 20px 20px;
    }

   
}
@media (max-width: 639px) {
    .stateWrap {
      
        padding: 0;
        padding-bottom: 40px;
    }
    .stateContainer {
        padding-top: 40px;
       
    }
}