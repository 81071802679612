.sliderimg{
    width: 100%;
    height: 600px;
    object-fit: cover
  }
  .card{
    background-color: #f3f3f3;
    margin:auto;
    width:90%;
    
    height: 866px;
  }
.carousel{
    padding-left: 100px;
    align-items: center;
    width:50%;
  }
.heading{
    padding-left: 130px;
    padding-bottom: 200px;
    color: #006782;
}
.box{
  position:relative;
  bottom: 364px;
}