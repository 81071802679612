.headline {
    height: 120px;
    }
@media all and (min-width:1025px) {
    .headline {
        height: 220px;
        }
}
@media(max-width:767px) {
    .headline {
        height: 160px;
        }
}

@media(max-width:639px) {
    .headline {
        height: 130px;
        }
}

.fadeIn {
    transition: opacity 0.21s ease;
}
.fadeOut {
    opacity: 0;
    transition: opacity 0.21s ease;
}