.bgHeaderBackground {
    background-image: url('../../assets/img/Contact.PNG');
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(max-width:767px) {
      height: 200px;
    }
  }

.contactWrap {
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 30px;

      h3 {
        color: #2D6A80;
        text-transform: uppercase;
         font-weight: 400;
         font-family: "themixarabicExBold", sans-serif;
         font-size: 22px;
         margin: 0 0 20px 0;
      }
}

.contactContain {
  h2 {
    color: #2D6A80;
    margin: 0 0 20px 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-family: "themixarabicBold", sans-serif;

    &.contactTopTitle {
        margin-top: 25px;
    }
  }
}

.contactBox {
    border: solid 1px #2D6A80;
    padding: 30px 40px;

    p {
        color: #2D6A80;
        font-family: "themixarabicBold", sans-serif;
        margin-bottom: 10px;

        a {
          cursor: pointer;
            text-decoration: underline;
        }
    }
}

.contactMap {
    padding: 55px 0;

    
}

.contactForm {
  padding: 0 0 55px 0;
   
}
.contactSocial {
  display: flex;
  margin-top: 40px;

  a {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 27px;

    img {
      transition: .2s;
  }

  &:hover img {
      filter: invert(55%) sepia(66%) saturate(2829%) hue-rotate(0deg) brightness(102%) contrast(106%);
  }
  }
}

.capitalize{
  text-transform: capitalize;
}