.background {
  background-image: url('../../assets/img/CustomerPortal.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #003a4d33;
  background-blend-mode: soft-light;
  background-position: 0%;
  display: flex;
  justify-content: right;
  padding: 70px 200px 70px 0;

  @media (max-width: 767px) {
    // height: 200px;
    padding: 0 0 0 0;
  }
}
// [dir='rtl'] .background {
//   justify-content: left;
//   padding: 70px 0 70px 200px;
// }
.whiteBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #276a7f;
  background: #ffffff;
  border-radius: 8px;
  padding-block: 30px;
  width: 530px;
}
.topPart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding-bottom: 20px;
  padding: 20px 30px 20px 30px;
  font-family: 'themixarabicBold', sans-serif;
  h1 {
    font-family: 'themixarabicBold', sans-serif;
    font-size: 30px;
    font-weight: 530;
    text-align: center;
    padding: 10px 10px 0 10px;
    line-height: normal;
  }
  p {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #000000;
    font-family: 'themixarabicLight', sans-serif;
    align-self: center;
    text-align: center;
    padding: 10px;
  }
  button {
    background-color: inherit;
    border-radius: 0;
    font-family: 'themixarabicLight', sans-serif;

    a {
      color: #ffffff;
      margin: 5px 0 5px 0;
      font-size: 18px;
      line-height: 40px;
      font-weight: 400;
      font-family: 'themixarabicBold', sans-serif;
      background: #276a7f;
      border-radius: 8px;
      border: #000000 solid 2px;
      padding: 10px 30px 10px 30px;
      width: 180px;
      height: 50px;
      &:hover {
        opacity: 0.4;
      }
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
  }
}
.border {
  color: #ffffff;
  height: 1px;
  border: 1px solid #276a7f;
  margin-inline: 100px;
}
.bottomPart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px 20px 30px;
  font-family: 'themixarabicBold', sans-serif;
  h1 {
    font-family: 'themixarabicBold', sans-serif;
    font-size: 30px;
    font-weight: 530;
    text-align: center;
    padding: 10px 10px 0 10px;
  }
  p {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #000000;
    font-family: 'themixarabicLight', sans-serif;
    align-self: center;
    text-align: center;
    padding: 10px;
  }
  button {
    background-color: inherit;
    border-radius: 0;
    font-family: 'themixarabicLight', sans-serif;

    a {
      color: #ffffff;
      margin: 5px 0 5px 0;
      font-size: 18px;
      line-height: 40px;
      font-weight: 400;
      font-family: 'themixarabicBold', sans-serif;
      background: #42a3b9;
      border-radius: 8px;
      border: #000000 solid 2px;
      padding: 10px 30px 10px 30px;
      width: 180px;
      height: 50px;
      &:hover {
        opacity: 0.4;
      }
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
  }
}
.image {
  display: flex;
  justify-content: center;
  padding: 30px 0 30px 0;
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(239deg)
    brightness(200%) contrast(107%);
  img {
    height: 219px;
    width: 140px;
  }
}
.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 48px;
  line-height: 63px;
  color: #ffffff;
  padding: 30px 0 30px 0;
}
.buttons {
  display: flex;
  justify-content: center;
  font-family: 'themixarabicLight', sans-serif;
  gap: 200px;
}
.buttontiles {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 1px solid #276a7f;
  background: #ffffff;
  border-radius: 8px;
  width: 504px;
  height: 393px;
  h1 {
    color: #000000;
    font-family: 'themixarabicBold', sans-serif;
    font-size: 28px;
  }
  p {
    width: 391px;
    text-align: center;
    font-size: 24px;
    color: #000000;
    font-family: 'themixarabicLight', sans-serif;
  }
  button {
    background-color: inherit;
    border-radius: 0;
    &:hover {
      opacity: 0.4;
    }
    a {
      color: #ffffff;
      margin: 5px 0 5px 0;
      font-size: 18px;
      line-height: 40px;
      font-weight: 400;
      font-family: 'themixarabicBold', sans-serif;
      background: #276a7f;
      border-radius: 8px;
      border: #000000 solid 2px;
      padding: 10px 30px 10px 30px;
      width: 180px;
      height: 50px;
    }
  }
}
.guide {
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #3da3bd;
  text-decoration: underline;
  padding: 10px 10px 10px 10px;
}
.container_max {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 80vw;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100vw;
  }
}
.block {
  display: flex;
}
.list {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  p {
    color: #ffffff;
    font-size: 18px;
    margin: 5px;
  }
}
.container {
  display: flex;
  color: #ffffff;
  border: solid 2px #000000;
  border-radius: 10px;
  padding: 5px;
  background-color: #41a3b9;
  margin: 5px;
  font-family: 'themixarabicLight', sans-serif;

  h2 {
    font-size: 20px;
    margin: 0;
    padding-left: 20px;
  }
  p {
    font-size: 16px;
    margin: 0;
    padding-left: 20px;
  }
}

.titleAndButton {
  display: flex;
  justify-content: space-between;
}
.userGuide {
  display: flex;
  justify-content: space-around;
  color: #ffffff;
  font-family: 'themixarabicBold', sans-serif;
  padding-top: 10px;
}
