@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@material-tailwind/react/tailwind.css';

@layer utilities {
  .-translate-x-1\/2 {
    --tw-translate-x: -50% !important;
  }
}

/* @media (min-width: 768px) {
.container {
    max-width: 1112px;
}
} */

@font-face {
  font-family: 'themixarabicBold';
  src: url('../fonts/TheMixArabic-Bold.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'themixarabicExBold';
  src: url('../fonts/TheMixArabic-ExtraBold.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'themixarabicLight';
  src: url('../fonts/TheMixArabic-Light.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'themixarabicPlain';
  src: url('../fonts/TheMixArabic-Plain.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'themixarabicBlack';
  src: url('../fonts/TheMixArabic-Black.otf');
  font-weight: 400;
}
/* @media (min-width: 768px) {
    .container {
        max-width: 768px;
        padding-right: 5rem;
        padding-left: 5rem;
    }
  }
  @media (min-width: 1024px) {
    .container {
        max-width: 1024px;
        padding-right: 5rem;
        padding-left: 5rem;
    }
  
  }
  @media (min-width: 1280px) {
    .container {
        max-width: 1280px;
        padding-right: 5rem;
        padding-left: 5rem;
    }
  }
   */
.container_max {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 80vw;
  margin: 0 auto;
  width: 100%;
  @media(max-width:767px) {
    max-width: 100vw;
  }
}
/* @media (min-width: 1280px) {
    .container {
        max-width: 1340px;
       
    }
  }


  
   @media(max-width:639px) {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
   } */

strong,
.font-bold {
  font-family: 'themixarabicBold' !important;
  font-weight: 400;
}

.text-primary {
  color: #006782;
}

html {
  font-family: 'themixarabicLight', sans-serif;
}

h1 {
  font-family: 'themixarabicLight', sans-serif;
  font-size: 53px;
  line-height: 63px;
}

.history-carousel .alice-carousel__dots {
  text-align: left;
  display: flex;
  max-width: 685px;
  margin: 45px 0 0;
}
.history-carousel .alice-carousel__dots-item {
  width: 100%;
  margin-right: 13px;
  padding: 10px 0;
  cursor: pointer;
}
.history-carousel .alice-carousel__dots-item span {
  text-align: left;

  background: #808080;
  display: block;
  height: 2px;
}
body[dir='rtl'] {
  .alice-carousel__dots 
  {
  transform: scaleX(-1);
  }
}

.history-carousel .alice-carousel__dots-item.__active span {
  background: #ff8300;
}

.history-carousel .alice-carousel__dots-item:last-child {
  margin: 0;
}

.history-carousel .alice-carousel__dots-item span {
  margin: 0;
}

.media-carousel {
  position: relative;
}

.media-carousel .alice-carousel__dots {
  text-align: left;
  display: flex;
  max-width: 1280px;
  position: relative;
  margin: 75px 0 0 0;
}

.media-carousel .alice-carousel__dots::after {
  content: '';
  position: absolute;
  bottom: 11px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e1e1e1;
}

.media-carousel .alice-carousel__dots-item {
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
}

.media-carousel .alice-carousel__dots-item span {
  text-align: left;

  background: none;
  display: block;
  height: 4px;
  position: relative;
  z-index: 1;
}

.media-carousel .alice-carousel__dots-item.__active span {
  background: #ff8300;
}

.media-carousel .media-dates strong {
  position: absolute;
  bottom: -8px;
  margin: 0;
  font-size: 14px;
}

.media-carousel .media-dates .media-date-first {
  left: 0;
}

.media-carousel .media-dates .media-date-last {
  right: 0;
}

.home-image-carousel .alice-carousel__dots-item {
  margin: 0 4px;
}

.form-field {
  margin-bottom: 30px;
  width: 100%;
}
.form-field label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-family: 'themixarabicPlain', sans-serif;
}

.form-field label .require {
  color: #ef3061;
}

.form-field input,
.form-field select,
.form-field textarea {
  background: #f2f2f2;
  border: solid 1px #f2f2f2;
  width: 100%;
  padding: 10px;
}

.form-field input:focus,
.form-field input:focus-visible,
.form-field select:focus,
.form-field select:focus-visible,
.form-field textarea:focus,
.form-field textarea:focus-visible {
  border-color: #2d6a80;
  outline: solid 3px rgba(45, 106, 128, 0.25);
}

.react-date-picker__wrapper {
  border: solid 1px #f2f2f2 !important;
  background: #f2f2f2;
  height: 45px;
  padding: 0;
}

.react-date-picker__wrapper input {
  height: 100%;
  padding: 0 10px;
}

.licenseSteps {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;
  grid-template-columns: repeat(6, 1fr);
}

.licenseSteps .step {
  padding: 15px 15px;
  border: 1px solid #e3e3e3;
  background: #f9f9f9;
}

.licenseSteps .number {
  font-family: 'themixarabicBold';
  font-size: 28px;
  color: #fcb732;
  text-align: center;
  border-radius: 50%;
  border: solid 2px #48a2b8;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background: #fff;
}

.licenseSteps .title {
  font-size: 14px;
  font-family: 'themixarabicPlain';
}

@media (max-width: 1023px) {
  .licenseSteps {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 639px) {
  .licenseSteps {
    grid-template-columns: repeat(2, 1fr);
  }
}

.serviceFees {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;
  grid-template-columns: repeat(3, 1fr);
}

.serviceFees .fee {
  padding: 15px 15px;
  background: #2d6a80;
  display: flex;
  flex-direction: column;
}

.serviceFees .title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  font-family: 'themixarabicBold';
}

.serviceFees .price {
  color: #fff;
  font-size: 16px;
  margin-top: auto;
  font-family: 'themixarabicLight';
}

@media (max-width: 1023px) {
  .serviceFees {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 639px) {
  .serviceFees {
    grid-template-columns: repeat(2, 1fr);
  }
}
.serviceListBullet {
  list-style-type: disc;
  padding-left: 20px;
}

.serviceListNumber {
  list-style: decimal;
  padding-left: 20px;
}

body[dir='rtl'] .serviceListBullet {
  padding-left: 0;
  padding-right: 20px;
}
body[dir='rtl'] .serviceListNumber {
  padding-left: 0;
  padding-right: 20px;
}
.button-default {
  background: #00b2e3;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  font-family: 'themixarabicLight', sans-serif;
  outline: 0;
  box-shadow: none;
  transition: 0.2s;
  color: #fff;
  padding: 9px 45px;
}

.button-default:focus {
  outline: 0;
}
.button-default:hover {
  outline: 0;
}

.page_wrapper {
  margin-top: -60px;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
}

.page_wrapper .page_title {
  font-size: 53px;
  line-height: 63px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 0 30px 0;
  font-family: 'themixarabicBold', sans-serif;
}

.page_wrapper_sub {
  padding: 0 30px;
}

.greyExtendWrap {
  margin: 0 calc(50% - 50vw);
  padding-top: 55px;
  padding-bottom: 55px;
  background: #f3f3f3;
  position: relative;
}

@media (max-width: 767px) {
  .page_wrapper {
    margin-top: 0;

    padding: 15px 0;
  }
  .page_wrapper_sub {
    padding-right: 0;
    padding-left: 0;
  }
  .page_wrapper .page_title {
    font-size: 24px;
    line-height: 34px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .greyExtendWrap {
    padding: 40px 0;
  }
}

/* During development there is an iframe that pops up whenever a change is made so this just gets rid of it */
iframe[style*='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
  display: none !important;
  z-index: -1 !important;
  width: 0px !important;
  top: -100px !important;
  left: -100px !important;
}
