.bgHeaderBackground {
  background-image: url('../../assets/img/regulations_header.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}

.regulationsCard {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  margin-top: -80px;
}
.eServiceWrapper > div {
  margin: 0 auto;
  margin-top: -60px;
  margin-top: -60px;
  border-radius: 20px;
  width: 80%;
  background-color: #fff;
  padding: 10px 20px;
}

.regulationSectionButton {
  cursor: pointer;
  padding: 9px 20px;
  border: solid 1px #bbb;
  font-family: 'themixarabicPlain', sans-serif;
  border-radius: 45px;
  font-size: 17px;
  text-transform: none;
  letter-spacing: 0;
  box-shadow: none;
  color: #000;

  &:hover {
    box-shadow: none;
    border-color: #2d6a80 !important;
    background: #2d6a80 !important;
    color: #fff !important;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  gap: 10px 5px;

  @media (max-width: 875px) {
    margin-bottom: 0;
  }
}

.subHeading {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 865px;
  margin-bottom: 20px;

  color: rgba(0, 0, 0, 0.8);
}

.pdfSectionContainer {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap:20px;
}
.pdfSectionWrap {
  padding-bottom: 55px;
}
.regTable {
  width: 100%;
  font-size: 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);

  @media (max-width: 767px) {
    font-size: 14px;
  }

  th {
    font-family: 'themixarabicBold', sans-serif;
    font-weight: 400;
    padding: 16px 0;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    &:first-child {
      text-align: left;
    }
  }

  td {
    padding: 16px 0;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    &:first-child {
      text-align: left;
    }
  }
}

body[dir='rtl'] {
  .regTable {
    th,
    td {
      &:first-child {
        text-align: right;
      }
    }
  }
}

div > tr {
  display: none;
}
.pdfDownloadIcon {
  display: inline-block;

  svg {
    width: 24px;
    height: 24px;
  }
}

.square {
  border: 1px solid #e3e3e3;
  padding: 20px 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 190px;
  align-items: center;
  width: 100%;
}

.squareWrap {
  gap: 5px;
  display: flex;
  max-width: 213px;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  align-items: center;

  @media (max-width: 550px) {
    margin: 0 20px 20px 0;
    width: calc(50% - 20px);
  }
}

body[dir='rtl'] {
  .squareWrap {
    margin: 0 0 30px 30px;

    @media (max-width: 550px) {
      margin: 0 0 20px 20px;
    }
  }
}

.pdfFileNames {
  font-family: 'themixarabicPlain', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 0 20px;
  margin-top: 15px;
  text-align: center;

  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 550px) {
    padding: 0;
  }
}
.title {
  height: 126px;
  font-family: 'SF Compact Text';
  font-style: normal;
  font-weight: 900;
  font-size: 53px;
  line-height: 63px;
  /* or 119% */
  text-transform: uppercase;
  color: #2d6a80;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.regulationHeader {
  display: flex;
  justify-content: space-between;

  h2 {
    color: #2d6a80;
    margin: 0 0 20px 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-family: 'themixarabicBold', sans-serif;
  }
}

.regIcon {
  color: #000;
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 18px;
}

.regIconList {
  width: 28px;
  height: 28px;
  margin-top: -3px;
}
.regIconWrap {
  display: flex;
  @media (max-width: 875px) {
    margin-bottom: 20px;
  }
}
.regulationBody {
  margin-bottom: 20px;
}
.mediaCenterContent {
  h2 {
    font-weight: 400;
    font-size: 30px;
    font-family: 'themixarabicBold';
    margin: 0 0 10px 0;
    color: rgba(0, 0, 0, 0.8);
  }
}

.sideBar {
  max-width: 300px;
  width: 230%;

  @media (max-width: 767px) {
    max-width: 230%;
  }
}
.sideBarTwo {
  max-width: 930px;
  width: 230%;

  @media (max-width: 767px) {
    max-width: 230%;
  }
}

.sideBarSub {
  margin-top: 60px;
}

.mediaCenterGrid {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;

  grid-template-columns: repeat(3, minmax(30%, 1fr));

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }

  @media (max-width: 639px) {
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
}

.mediaContain {
  display: flex;
  flex-direction: column;
  @media (min-width: 767px) {
    flex-direction: row;
    gap: 3rem;
  }
}

.tabContent{
  width: 100px;
}

.tabpdf{
  padding-bottom: 15px;
}
