.bgHeaderBackground {
  background-image: url('../../assets/img/ports/Dammam_Port.jpg');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    height: 200px;
  }
}

.portTitle {
  color: #2d6a80 !important;
  text-transform: uppercase;
  font-family: 'themixarabicExBold', sans-serif !important;
}
.eServiceWrapper > div {
  margin: 0 auto;
  margin-top: -60px;
  margin-top: -60px;
  border-radius: 20px;
  width: 80%;
  background-color: #fff;
  padding: 10px 20px;
}

.accordianWrapper > div {
  background: none !important;
  box-shadow: none;
  position: static;
  > div {
    padding: 0 !important;
  }

  .bgImage1 {
    background-image: url('../../assets/img/accordian1.png');
    height: 150px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .accordianHeader {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      background: rgba(45, 106, 128, 0.7);
      height: 100%;
      border-radius: 15px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 30px;
      padding: 0px 40px;
      font-family: 'themixarabicBold', sans-serif;
    }
  }
}

.selectStyle {
  box-sizing: border-box;
  width: 190px;
  height: 37px;
  border: 2px solid #2d6a80;
  border-radius: 5px;
}
.applyButton {
  background: #2d6a80;
  border: 2px solid #2d6a80;
  border-radius: 5px;
  width: 140px;
  height: 37px;
  color: white;
}
.assetTableLeft {
  background: rgba(45, 106, 128, 0.6);
  border-start-start-radius: 0.25rem;
  border-end-start-radius: 0.25rem;
}
.assetTableRight {
  background: #f4f4f4;
  border-start-end-radius: 0.25rem;
  border-end-end-radius: 0.25rem;
}
.title {
  height: 126px;
  font-family: 'SF Compact Text';
  font-style: normal;
  font-weight: 900;
  font-size: 53px;
  line-height: 63px;
  /* or 119% */
  text-transform: uppercase;
  color: #2d6a80;
}
.portsContainer {
  display: grid;
  grid-gap: 15px;
  margin: 20px 0;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 619px) {
    display: block;
  }
}
.portItem {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/img/ports/thumbs/Jeddah_Port_Thumb.jpg');
  background-size: cover;

  margin: 1%;
  color: #fff;
  padding: 10px;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'themixarabicBold', sans-serif;
  font-size: 18px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#2d6a80, 0.7);
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }

  &:hover {
    cursor: pointer;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &.jeddah-islamic-port {
    background-image: url('../../assets/img/ports/thumbs/Jeddah_Port_Thumb.jpg');
  }
  &.king-abdul-aziz-port-in-dammam {
    background-image: url('../../assets/img/ports/thumbs/Dammam_Port_Thumb.jpg');
  }
  &.king-fahad-industrial-port-at-jubail {
    background-image: url('../../assets/img/ports/thumbs/Jubail_Industrial_Port_Thumb.jpg');
  }
  &.yanbu-commercial-port {
    background-image: url('../../assets/img/ports/thumbs/Yanbu_Commercial_Port_Thumb.jpg');
  }
  &.king-fahad-industrial-port-yanbu {
    background-image: url('../../assets/img/ports/thumbs/Yanbu_Industiral_Port_Thumb.jpg');
  }
  &.jubail-commercial-port {
    background-image: url('../../assets/img/ports/thumbs/Jubail_Commercial_Port_Thumb.jpg');
  }
  &.ras-alkhair-port {
    background-image: url('../../assets/img/ports/thumbs/Ras_Alkheir_Port_Thumb.jpg');
  }
  &.jizan-port {
    background-image: url('../../assets/img/ports/thumbs/Jizan_Port_Thumb.jpg');
  }
  &.dhiba-port {
    background-image: url('../../assets/img/ports/thumbs/Jizan_Port_Thumb.jpg');
  }
}

@media(max-width:500px) {
  .portItem {
    margin: 3% 1%;
  }
}

.activePort{
  &::after {
    background: rgba(#2d6a80, 0.8) !important;
    border:3px solid #2d6a80;
    -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
    filter: grayscale(0%);
  }
}

.articleWrap {
  white-space: pre-line;
}

.portsGrid {
  min-width: 20%;
}

body[dir='rtl'] {
  // used by popup on Map on homescreen
  .popupContainer {
    :global {
      .leaflet-popup-close-button {
        right: unset;
        left: 0;
      }
    }
  }
}
