.announcement{
    padding:10px;
    border:1px solid #b64956;
    background:#f2dede;
    color:#b64956;
    display: flex;
    gap:10px;
    flex-direction: row;
    margin-bottom: 10px;
    
    & .title{
      font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    font-family: "themixarabicBold", sans-serif;
    text-align: center;
  
    } 
    & .description{
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
      text-align: justify;
      font-family: "themixarabicPlain", sans-serif;
      text-align: center;
  
    }
  }