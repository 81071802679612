.sliderImage {
  align-items: flex-end;
  height: 400px;
  max-height: 70vh;
  width: 100%;
  object-fit: contain;
  object-position:top;
}
@media screen and (min-width: 850px) {
  .sliderImage {
    height: 600px;
    max-height: 720px !important;
  }
}

@media screen and (max-width: 500px) {
  .sliderImage {
    height: auto;
  }
}

.sliderNewsImage {
  align-items: flex-end;
  height: auto;
  max-height: 70vh;
  width: 70%;
  object-fit: contain;
}
@media screen and (min-width: 850px) {
  .sliderNewsImage {
    max-height: 720px !important;
  
  }
}

@media screen and (max-width: 500px) {
  .sliderNewsImage {
    height: auto;
    width: 100%;
    max-height:200px !important;
  }
}


.carousel {
  align-items: center;
  width: 50%;
}
.heading {
  padding-left: 190px;
  top: 170px;
  position: relative;
  font-family: 'TheMixArabic';
  font-style: normal;
  color: 'blue';
  text-transform: uppercase; 
  font-weight: 540;
  font-size: 53px;
  line-height: 63px;
}

.history-slider-container--outer {
  /* max-width: 685px; */
  width: 100%;
}
.history-slider-container--outer * {
  width: 100%;
}
