.subscribe {
  border-top: solid 2px #f7f7f7;
  padding: 60px 0 60px 0;

  h2 {
    font-family: "themixarabicPlain", sans-serif;
    margin: 0 0 35px 0;
    font-size: 40px;
    font-weight: 400;
  }
}

.subscribeForm {
  display: flex;
  align-items: flex-end;
  width: 80%;

  input {
    padding: 0 0 17px 0;
    width: 100%;
    color: rgba(0,0,0,.7);
    outline: none;
    border-bottom: 2px solid #d6d6d6;
    font-size: 30px;
    transition: .2s;
    font-family: "themixarabicPlain", sans-serif;

    &:focus {
      border-color: #00B2E3;
    }

    &::-webkit-input-placeholder { 
      color: rgba(0,0,0,.5);
    }
    
    &:-ms-input-placeholder { 
      color: rgba(0,0,0,.5);
    }
    
    &::placeholder {
      color: rgba(0,0,0,.5);
    }
  }
}

.subscribeButton {
  background: #00B2E3;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  font-family: "themixarabicLight", sans-serif;
  outline: 0;
  box-shadow:none;
  transition: .2s;
  color: #fff;
  padding: 9px 45px;
  margin-left: 25px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: darken(#00B2E3,5%);
  }
}

@media(max-width:639px) {
  .subscribe {
  
  padding: 40px 0;
    h2 {
      font-size: 30px;
     line-height: 40px;
    }
  }

  .subscribeForm {
    width: 100%;
    padding-bottom: 10px;
    input {
      font-size: 20px;
    }
  }
  
.subscribeButton {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}
}