.footer {
  padding: 65px 0 0;
  border-top: solid 2px #f7f7f7;
}

.footerRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  flex-wrap: wrap;
}

.footerGroup,
.footerGroupSplit {
  .footerTitle {
    font-family: 'themixarabicBold', sans-serif;
    font-size: 20px;
    display: block;
    margin: 0 0 10px 0;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }

    a {
      transition: 0.2s;
      color: rgba(#54565a, 0.8);

      &:hover {
        color: #000;
      }
    }
  }
}
.footerBadge {
  width: 300px !important;
}

.footerListPadding {
  padding-right: 40px;
}

.footerGroup,
.footerGroupSplit {
  margin-bottom: 30px;

  &:last-child {
    padding: 0;
    margin-right: 3%;
  }
}

.footerGroupSplit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div + div .footerTitle {
    margin: 0;
  }
}

.footerRibbon {
  background: #000;
  color: rgba(255, 255, 255, 0.8);
  padding: 7px 0;
  font-size: 14px;
  font-family: 'themixarabicLight', sans-serif;

  ul {
    display: flex;
  }

  li {
    margin: 0 22px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 1023px) {
  .footerRibbon {
    li {
      margin: 0 5px;
    }
  }
}
@media (max-width: 767px) {
  .footerRibbon {
    ul {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 639px) {
  .footer {
    padding: 45px 0 0;
    border-top: solid 2px #f7f7f7;
  }

  .footerGroup,
  .footerGroupSplit {
    &:last-child {
      margin-right: 0%;
    }
  }
}
