.informationWrap {
  padding: 72px 0px;
  background-color: #006782;
  color: white;
}
.infoTiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 1rem;

  .infoTile {
    // padding-right: 40px;
    // margin-bottom: 95px;
    display: block;
    min-width: calc((100vw - 100px)/3);
    &:last-child {
      padding-right: 0;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 10px 0;
    font-family: 'themixarabicBold', sans-serif;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    // color: #54565a;
    max-width: 75%;
    // opacity: 0.8;
  }
}

.infoTilesSocial {
  display: flex;

  h3 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    margin: 0px;
    margin-inline-end: 74px;
    font-family: 'themixarabicBold', sans-serif;
  }
  .infoTile {
    display: flex;
    align-items: center;
  }
  .infoSocial {
    display: flex;

    a {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 27px;

      img {
        transition: 0.2s;
      }

      &:hover img {
        filter: invert(55%) sepia(66%) saturate(2829%) hue-rotate(0deg)
          brightness(102%) contrast(106%);
      }
    }
  }
}

.infoTileMargin {
  margin: 0 10px;
}

@media (max-width: 767px) {
  .infoTiles {
    .infoTile {
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 639px) {
  .infoTileMargin {
    margin: 0px;
  }

  .informationWrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .infoTiles {
    display: block;

    .infoTile {
      padding: 0;
      margin-bottom: 30px;
    }
  }
  .infoTiles h3,
  .infoTilesSocial h3 {
    font-size: 26px;
  }

  .infoTilesSocial {
    .infoTile {
      display: block;
    }
    h3 {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}


