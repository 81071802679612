.bgHeaderBackground {
  // background-image: url('../../assets/img/news/1.jpg');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media(max-width:767px){
    width:100vw;
    height:20vh;
  }
}

.col2Wrapper > div {
  margin: 0 auto;
  margin-top: -60px;
  margin-top: -60px;
  border-radius: 20px;
  width: 80%;
  background-color: #fff;
  padding: 25px 30px 30px 30px;
}

.submitBtn {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 30px;

  button {
    border-radius: 30px;
  }
}

.articleContent {
  p {
    font-size: 21px;
    line-height: 30px;
  }
}

.readLink {
  text-transform: uppercase;
  font-family: 'themixarabicBlack', sans-serif;
  color: #ff8300;
  text-decoration: underline;

  display: block;
  margin-top: 40px;
}
.imgStyle {
  // max-width: 500px;
  margin: auto;
  width: 100%;
}

.imagesContainer {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem;

  img {
    height: 300px;
    object-fit: cover;
    overflow: hidden;
    cursor: pointer;
  }
}

.articleWrap {
  white-space: pre-line;
  text-align: justify;
  font-size: 18px;
}
.homeHistoryTitle,
.homeServiceTitle,
.homeCenterTitle {
  font-size: 38px;
  line-height: 48px;
}
.titleContainer {
  margin-bottom: 30px;
}

.dateText {
  font-family: 'themixarabicPlain', sans-serif;
  color: #54565a;
  display: block;
  margin-top: -20px;
  @media(max-width:767px){
    margin-top: auto;
  }
}

.lightboxContainer {
  cursor: pointer;
}
.modalContentContainer {
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid !important;
  outline: 0px solid !important;
  width: 100%;

  .close-btn {
    top: 0;
    position: absolute;
  }
  img {
    max-height: 400px;
    width: auto;
    object-fit: cover;

    border: 0px !important;
    cursor: default;
  }
}
