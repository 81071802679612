.informedWrapper {
  background-color: #006782;
  padding: 72px 0;
  color: white;
  font-size: 30px;
}

@media(max-width:639px) {
  .informedWrapper {
    padding: 40px 0;
    h1 {
      font-size: 38px;
      line-height: 48px;
    }
  }
}