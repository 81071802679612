.visible {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1240px) {
    top: 150px;
  }
  @media (max-width: 600px) {
    top: 620px;
  }
}

.hidden {
  display: none;
}

.content {
  padding: 20px;
  border-radius: 5px;
  background-color: #006782;
  width: 600px;
  color: #ffffff;
  font-family: 'themixarabicBold', sans-serif;

  // @media (max-width: 850px) {
  //   padding: 20px;
  //   bottom: 100;
  //   border-radius: 5px;
  //   width: 300px;
  // }
}

.title {
  text-align: center;
  padding: 10px;
}

.subTitle {
  text-align: center;
  text-decoration: underline;
}
.announcement {
  text-align: justify;
  padding-top: 15px;
  // text-decoration: underline;
}
.announcementTwo {
  text-align: justify;
  // display: inline-block;
  // text-decoration: underline;
  padding-top: 10px;
}
.announcementThree {
  text-align: center;
  // display: inline-block;
  padding-top: 15px;
  // text-decoration: underline;
}
.announcementHere {
  text-align: justify;
  // display: inline-block;
  padding-top: 15px;
  // text-decoration: underline;
}
.afterAnnouncement {
  text-align: center;
  padding-top: 15px;
  padding-right: 5px;
}
.announcementReadMore {
  text-align: justify;
  text-decoration: underline;
  padding-top: 10px;
}
.button {
  font-size: 18px;
  text-transform: none;
  outline: 0;
  box-shadow: none;
  transition: 0.2s;
  padding: 0px 7px;
  opacity: 1;
  text-align: start !important;
}
